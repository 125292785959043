.minus {
  width: 43px;
  height: 43px;
  border-radius: 8px;
  padding: 0px 5px 0px 5px;

  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #b5b5b5;
  cursor: pointer;
  user-select: none;
}

.minus_small {
  width: 30px;
  height: 30px;
  border-radius: 8px;
  padding: 0px 5px 0px 5px;

  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #b5b5b5;
  cursor: pointer;
  user-select: none;
}

.minus_xtra_small {
  width: 25px;
  height: 25px;
  border-radius: 8px;
  padding: 0px 5px 0px 5px;

  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #b5b5b5;
  cursor: pointer;
  user-select: none;
}

.plus {
  width: 43px;
  height: 43px;
  border-radius: 8px;
  padding: 0px 5px 0px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #1dc468;
  cursor: pointer;
  user-select: none;
}

.plus_small {
  width: 30px;
  height: 30px;
  border-radius: 8px;
  padding: 0px 5px 0px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #1dc468;
  cursor: pointer;
  user-select: none;
}

.plus_xtra_small {
  width: 25px;
  height: 25px;
  border-radius: 8px;
  padding: 0px 5px 0px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #1dc468;
  cursor: pointer;
  user-select: none;
}

.counter_input {
  width: 43px;
  height: 43px;
  border: 0px;
  background-color: #f3f4f6;
  text-align: center;
  font-size: 11pt;
  padding: 3px 0 0 0;
  font-weight: 600;
}

.counter_input_small {
  background-color: #f3f4f6;
  height: 30px;
  width: 30px;
  border: 0px;
  border-radius: 8px;
  text-align: center;
  font-size: 9pt;
  padding: 3px 0 0 0;
  font-weight: 600;
}

.counter_input_xtra_small {
  background-color: #f3f4f6;
  height: 25px;
  width: 30px;
  border: 0px;
  border-radius: 8px;
  text-align: center;
  font-size: 8pt;
  padding: 3px 0 0 0;
  font-weight: 600;
}
